<template>
  <div id="main-container" class="mdc-layout-grid__inner">
    <div class="mdc-layout-grid__cell--span-12">
      <div class="iframe-container">
        <iframe name="bills" id="bills-id" style="overflow:hidden" width="100%" height="100%" frameBorder="0"></iframe>
      </div>
    </div>
  </div>
</template>
<script>
import APICaller from "@/components/codeComponents/APICaller.js";
import { mapGetters } from "vuex";

export default {
  mixins:[APICaller],
  name: "Bills",
  components: {
  },
  data() {
    return {
    };
  },
  mounted() {
    this._setIframe();
  },
  computed: {
    ...mapGetters(["_g_User"]),
  },
  methods: {
    _setIframe(){
      var pricingFrame = document.getElementById("bills-id");
      pricingFrame.src = process.env.VUE_APP_API + '/mis-facturas'
    },
  }
};
</script>



<style lang="scss">

  #main-container{
    height:100%;
    width:100%;
    overflow:hidden !important;
  }

  .iframe-container{
    height:100% !important;
  }
  
  header{
    display:none !important;
  }

  .not-allowed-bills{
    text-align: center;
    margin-top: 50px;
    color: #FF9B00;
    font-weight: 500;
  }

</style>
